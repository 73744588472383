<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title" v-if="type === 'edit'">
                <p>序号：{{xuhao}}</p>
            </div>
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                <div class="content">
                    <div class="con-item">
                        <a-form-item label="纳税人识别号">
                            <a-input v-model:value="nashuirenshibiehao" placeholder="请输入纳税人识别号"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="省份">
                            <a-input v-model:value="shengfen" placeholder="请输入省份"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="年份">
                            <a-input v-model:value="nianfen" placeholder="请输入年份"></a-input>
                        </a-form-item>
                    </div>
                    <div class="con-item">
                        <a-form-item label="评级">
                            <a-input v-model:value="pingji" placeholder="请输入评级"></a-input>
                        </a-form-item>
                    </div>
                </div>
            </a-form>
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" style="margin-right: 20px;" @click="onSave">保存</a-button>
            <a-button type="primary" @click="$router.back()">取消</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'loginType',
            'isAuth',
            'orgId'
        ])
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            id: '',
            nashuirenshibiehao: '',
            shengfen: '',
            pingji: '',
            xuhao: '',
            nianfen: '',
            type: ''
        }
    },
    methods: {
        // 获取纳税评级详情
        getNSPJDetail () {
            this.$store.dispatch('getNSPJDetail', { JYDATA_NSPJ_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    let datas = res.pd
                    this.nashuirenshibiehao = datas.NASHUIRENCODE
                    this.shengfen = datas.PROVINCE
                    this.nianfen = datas.YEAR
                    this.pingji = datas.PINGJI
                } else {
                    this.$message.error('获取销项发票详情失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (this.type === 'add') {
                this.$store.dispatch('addNSPJItem', {
                    ENTERPRISE_ID: this.orgId,
                    NASHUIRENCODE: this.nashuirenshibiehao,
                    PROVINCE: this.shengfen,
                    YEAR: this.nianfen,
                    PINGJI: this.pingji,
                    tm: new Date().getTime()
                }).then(res => {
                    if (res.result === 'success') {
                        this.$message.success('添加成功！')
                        setTimeout(() => {
                            this.$router.back()
                        }, 1500)
                    } else {
                        this.$message.error('添加失败，请稍后重试！')
                    }
                })
            } else if (this.type === 'edit') {
                this.$store.dispatch('editNSPJItem', {
                    ENTERPRISE_ID: this.orgId,
                    JYDATA_NSPJ_ID: this.id,
                    NASHUIRENCODE: this.nashuirenshibiehao,
                    PROVINCE: this.shengfen,
                    YEAR: this.nianfen,
                    PINGJI: this.pingji,
                    tm: new Date().getTime()
                }).then(res => {
                    if (res.result === 'success') {
                        this.$message.success('修改成功！')
                        setTimeout(() => {
                            this.$router.back()
                        }, 1500)
                    } else {
                        this.$message.error('修改失败，请稍后重试！')
                    }
                })
            }
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
        this.type = this.$route.query.type
        this.xuhao = this.$route.query.num
        if (this.type === 'edit') {
            this.getNSPJDetail()
        }
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
    }
}
</style>